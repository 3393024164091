import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../font/style.css'
import './index.css'

class IndexPage extends React.Component {
  state = {
    iconData: null,
    term: "apple"
  }

  componentDidMount(){
    this.fetchByTerm("apple");
  }

  fetchByTerm(term){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      fetch(`https://api.svgapi.com/v1/Ty5WcDa63E/list/?search=${term}&limit=20`).then(res => res.json()).then(resJson => {
        this.setState({iconData: resJson});
      });
    }, 200);
  }

  renderIcons(){
    if(this.state.iconData){
      return this.state.iconData.icons.map((icon, i) => {
        return (<img src={icon.url} key={icon.id}/>);
      })
    }
  }

  render(){
    return (
      <Layout>
        <SEO title="svgapi - Documentation" description="Learn how to use the svgapi API to implement in your projects."/>
        <div className="register">
          <div className={this.state.registered ? "side registered" : "side"}>
            <div className="sideInner">
              <div className="header">
                <Link to="/"><img src={require("../images/logo_white.svg")} className="logo"/></Link>
              </div>
              <div className="sideInfo">
                <h1>Documentation</h1>
                <p>Learn how to use our API on this page.</p>
                <p><a style={{color: "#ddd"}} href="/contact">Contact us</a> if you face problems integrating with your system.</p>
              </div>
              <div className="sideLinks">
                <a href="https://panel.svgapi.com">Admin</a>
                <Link to="/pricing">Pricing</Link>
                <Link to="/documentation">Documentation</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>

          </div>


          <div className="sideHolder"></div>
          <div className="form">
            <div className="formInner">

              <h2>Domain Whitelisting</h2>
              <p>In order to use the API on your frontend system, you will need to add your domain on the admin panel. If not, you will not be able to make calls to your own API.</p>

              <div className="getRequest" style={{fontSize: "1em"}}>GET https://api.svgapi.com/v1/{`{{domain_key}}`}/endpoint</div>

              <p>For the calls, you need to replace <em>domain_key</em> to identify your account. This key can be found in your admin panel &gt; manage domains.</p>

              <h2>Listing SVG Icons</h2>
              <p>This endpoint lists icons by the searched term.</p>
              <div className="getRequest" style={{fontSize: "1em"}}>GET /v1/{`{{domain_key}}`}/list/?search=apple</div>
              <p>Call above will list 10 most likely search results for the term "apple" by default. You can use `%20` or plus (+) if your term contains multiple words. Also you can change the quantity of the icons you want to list.</p>

              <div className="getRequest" style={{fontSize: "1em"}}>GET /v1/{`{{domain_key}}`}/list/?search=apple&limit=20</div>
              <p>The parameter `limit` will control the quantity of listed icons, this value can be between 1-20.</p>

              <p>If the search result count is more than the limit, you can make a next page call to get more results, see the "next" property of your JSON output. Or use `start` parameter to specify the starting index of the listing.</p>
              <div className="getRequest" style={{fontSize: "1em"}}>GET /v1/{`{{domain_key}}`}/list/?search=apple&limit=20&start=40</div>
              <p>Above call will list 20 icons starting with 40th index. Or 3rd page page of the most relevant icons with `apple` term.</p>

              <h2>Development Usage</h2>
              <p>By default you can only fetch the API by your domain on frontend JavaScript. If you would like to fetch the API via a backend scripting language, you will need to toggle Development mode in your control panel. But make sure not to expose your domain_key when Development mode is toggled as others having access to it can use your call credit.</p>

              <footer style={{fontSize: "0.9em", paddingTop: 20, paddingBottom: 30}}>
                Copyright © {new Date().getFullYear()}, {` `} Dream Oriented Ltd.<br/>
                <small>We collect payments using Paddle.com's services with the company name Dream Oriented Ltd.</small>
                <br/>
                <small><a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a></small>
              </footer>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
